import { compareDataRequested, onResetCompareMode } from '../compare';
import { getSortOfferFunc } from '../../forms/shared/Ensfeatures';
import { navigateTo } from '../shared/navigateTo';
import { getUrl } from '../data';
import { evaluateFormular } from '../customFilter';
import { FilteredStartValue } from '../types/FilteredStartValue';
import { MultipartStreamToOfferLoadingStream } from '../shared/MultipartStreamParser';
import { getEnsFilter } from '../shared/getEnsFilter';
import { addCustomFilter, addUserDataPostFields } from './sharedPostField';
import { updateValidFilter } from '@/actions/customFilter/updateValidFilter';
import { getIsAutloadStandaloneMode } from '@/features/AutoloadEns';
import { resetFilterProposal } from '../customFilter/resetFilterProposal';
import { changeUserInput, getUserInputEventDebugInfo } from '../form';
import { getSavedFilterValues, hasSavedFilterValues } from '../customFilter/customFilterSaver';
import { HasPredef } from '@/features/Predef';
import { GetIsTestMode } from '@/forms/shared/IsDevMode';
import { openOverlay } from '@/processes/overlay/overlayReducer';

export { getEnsFilter } from '../shared/getEnsFilter';

export const applyDefaultUserData = () => {
  const debugInfo = getUserInputEventDebugInfo();

  return async (dispatch, getState: () => RootState) => {
    const state = getState();
    const filteredStartValues = getState().ensfields.userData_filteredStartValues;
    if (!filteredStartValues || !Array.isArray(filteredStartValues) || filteredStartValues.length === 0) return;

    const newValues = {};

    filteredStartValues.forEach((fsv: FilteredStartValue) => {
      const evalResult = evaluateFormular(fsv.filter, state.userData, state, null, true);
      if (!evalResult) return;
      Object.keys(fsv.value).forEach((key) => {
        newValues[key] = fsv.value[key];
      });
    });

    if (!HasPredef() && hasSavedFilterValues(state, 'coreFilter')) {
      const savedValues = getSavedFilterValues(state, 'coreFilter');
      Object.keys(savedValues).forEach((key) => {
        newValues[key] = savedValues[key];
      });
    }

    Object.keys(newValues).forEach((key) => {
      dispatch({
        type: 'CHANGE_USER_INPUT',
        value: { fieldName: key, fieldValue: newValues[key] },
        ...debugInfo,
      });
    });
  };
};

let abortController = new AbortController();
export const getRequestOfferAbortController = () => abortController;

const requestOffers = (
  skipNavigation = false,
  skipComapreTable = false,
  skipFilterProposal = false,
  options: {
    skipResetCompareMode?: boolean;
    skipBasicValidation?: boolean;
    selectedNames?: string[];
    skipDefaultUserDataLoading?: boolean;
    skipVoucherOverlay?: boolean;
  } | null = null
) => {
  return async (dispatch, getState: RootStateGetter) => {
    abortController.abort('Got a new request');
    abortController = new AbortController();

    const currentAbortSingal = abortController.signal;

    currentAbortSingal.addEventListener('abort', () => {
      dispatch({ type: 'ENSURANCES_LOAD_END' });
    });

    await dispatch(updateValidFilter());

    dispatch({ type: 'ENSURANCES_LOAD_STARTED' });

    const initialState = getState();

    if (!skipFilterProposal) {
      await dispatch(resetFilterProposal());
    }

    if (!skipNavigation && !options?.skipDefaultUserDataLoading) {
      await dispatch(applyDefaultUserData());
    }

    if (!skipNavigation) {
      if (initialState.customProducs.voucher_input_overlay && !options?.skipVoucherOverlay)
        await dispatch(openOverlay('loadingEnsPage'));
      else if (
        !options?.skipVoucherOverlay &&
        !initialState.userData.voucherCode &&
        initialState.ensfields.ens_fallback_vouchercode
      ) {
        await dispatch(changeUserInput('voucherCode', initialState.ensfields.ens_fallback_vouchercode));
      }

      dispatch({ type: 'RESET_TO_ENSLIST' });
      dispatch(navigateTo('ensuranceList'));
    }

    if (!skipComapreTable) {
      await dispatch(compareDataRequested());
    }

    if (!options?.skipResetCompareMode) dispatch(onResetCompareMode(true, false));

    const state = getState();
    const request = JSON.parse(JSON.stringify(state.userData));
    const formData = new FormData();
    addUserDataPostFields(state, request, formData);
    addCustomFilter(state, formData);

    if (options?.skipBasicValidation || getIsAutloadStandaloneMode(state))
      formData.append('skipBasicValidation', options?.skipBasicValidation ? 'true' : 'false');

    const hasSelectedNames = options?.selectedNames && options?.selectedNames.length > 0;

    if (hasSelectedNames) {
      formData.append('selectedNames', options.selectedNames.join(','));
    }

    let result: { result: any[] };

    if (!GetIsTestMode()) {
      const fetchingRequest = await fetch(getUrl('/wp-json/ens/v1/ensOffer/getOffersStream'), {
        body: formData,
        method: 'post',
        signal: abortController.signal,
      });

      // TextDecoderStream wird erst ab Firefox 104 unterstützt
      const DecoderSteam = TextDecoderStream
        ? TextDecoderStream
        : (await import('@/lib/TextDecoderStreamPolyfill')).PolyfillTextDecoderStream;

      const reader = fetchingRequest.body.pipeThrough(new DecoderSteam()).getReader();
      result = await MultipartStreamToOfferLoadingStream(reader, fetchingRequest.headers, (e) => {
        !currentAbortSingal.aborted &&
          dispatch({
            type: 'ENSURANCES_LOADING_INFO',
            payload: e,
          });
      });
    } else {
      result = await fetch(getUrl('/wp-json/ens/v1/ensOffer/getOffers'), {
        body: formData,
        method: 'post',
        signal: abortController.signal,
      }).then((r) => r.json());
    }

    if (currentAbortSingal.aborted) return;

    let offers = result?.result || [];

    if (typeof offers === 'object') {
      offers = Object.keys(offers).map((k) => offers[k]);
    }

    let resList = offers.sort(getSortOfferFunc());

    const ensFilter = getEnsFilter(getState())
      .split(',')
      .filter((o) => o)
      .map((o) => o.toUpperCase());
    if (ensFilter.length > 0) {
      resList = resList.filter((o) => ensFilter.includes((o.filterkey || '').toUpperCase()));
    }

    if (!skipFilterProposal) {
      if (result.filterProposalInfo)
        dispatch({
          type: 'FILTER_PROPOSALS_LOADED',
          filterProposals: result.filterProposalInfo.filterProposals,
          proposalText: result.filterProposalInfo.proposalText,
        });
    }

    dispatch({
      type: 'ENSURANCES_LOADED',
      list: resList,
      requestedEnsurances: hasSelectedNames ? options?.selectedNames || null : null,
    });

    // Sollte hier nicht mehr geladen werden.
    // Der Gutschein wird über einen seperaten call geladen
    // Wenn das hier drin ist, kann es race condision geben über die LoadingEnsPage
    /*     if (result.voucherInfo) {
      dispatch({
        type: 'ENS_VOUCHER_LOADED',
        valid: result.voucherInfo.valid,
        voucher: result.voucherInfo,
      });
    } else {
      dispatch({ type: 'ENS_VOUCHER_RESET' });
    } */
  };
};

export default requestOffers;
