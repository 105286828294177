import { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { HasPredef } from './Predef';
import { EntryQuestionPosition } from '@/actions/types/DynamicEntryQuestionType';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useDebounce } from '@/lib/useDebounce';
import { BikeUserDataType, DefaultObjectUserData, DefaultUserData } from '@/reducer/userData';
import { getOriginalWindowSearch } from '@/actions/shared/QueryParams';
import { PresetIdState } from '@/reducer/options';
import { GetIsDevMode } from '@/forms/shared/IsDevMode';
import { DeepPartialExcept } from '@/actions/types/lib/DeepPartial';
import { getIsAutloadStandaloneMode } from './AutoloadEns';
import { localStorageGet, localStorageSet } from '@/lib/EnsLocalStorage';
import { useIsProcedural } from '@/forms/OverviewControls/proceduralOverviewInput/ProceduralOverviewInput';
import { useEnsOptions } from '@/reducer/ensOptions';

let entryQuestionsLoaded = false;

const entryQuestionPositions: EntryQuestionPosition[] = [
  'entryQuestions',
  'entryQuestionsMore',
  'entryQuestionsTop',
  'objectEntryQuestions',
  'objectEntryQuestionsTop',
  'objectEntryQuestionsMore',
];

type SavedEntryQuestionState = {
  data: Partial<UserDataType>;
  devicemode: string;
};

type StorageKeyState = DeepPartialExcept<RootState, { options: { presetIdData } }>;

const getStorageKey = (presetName, state: StorageKeyState) => {
  let key = 'ens_entryquestion_v1_' + (presetName || 'default');

  if (getIsAutloadStandaloneMode(state)) {
    key += '_standalone';
  }

  return key;
};

export const getEntryQuestionsStorageKey = getStorageKey;

export const saveToLocalStorage = (data: SavedEntryQuestionState, presetName: string, state: StorageKeyState) => {
  localStorageSet(getStorageKey(presetName, state), JSON.stringify(data));
};

export const resetSavedState = (presetName: string, state: StorageKeyState) => {
  saveToLocalStorage(null, presetName, state);
};

export const loadFromLocalStorage = (presetName: string, state: StorageKeyState) => {
  return JSON.parse(localStorageGet(getStorageKey(presetName, state), null)) as SavedEntryQuestionState | null;
};

export const useSaverIsActive = () => {
  const presetIdState: PresetIdState = useAppSelector((state) => state.options.presetIdState);

  const presetName = useAppSelector((state) => state.userData.presetName);

  const centralEnable = useAppSelector((state) => state.ensfields.ens_save_entrquestions);

  const ensOptions = useEnsOptions();

  const ensPreset = useAppSelector((state) => state.ensPreset.ensPreset);

  const isProceduralProcess = useIsProcedural();

  return useMemo(() => {
    if (HasPredef()) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of predef');
      return false;
    }

    if (
      ensPreset?.customer &&
      Object.keys(ensPreset?.customer || {}).filter((e) => e !== 'deviceModeOverride').length > 0
    ) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of prefilled data');
      return false;
    }

    if (ensPreset?.bike || ensPreset?.object) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of prefilled data');
      return false;
    }
    if (!presetIdState) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of presetid not loaded yet');
      return false;
    }

    if (presetName) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of presetName ' + presetName);
      return false;
    }

    if (isProceduralProcess) {
      if (GetIsDevMode()) console.log('Skip EntryQuestionSaver because of proceduralInput');
      return false;
    }

    return (
      centralEnable === '1' &&
      !(
        queryString.parse(getOriginalWindowSearch()).ensDisableSaveEntryQuestion || ensOptions?.disableSaveEntryQuestion
      )
    );
  }, [presetIdState, isProceduralProcess, presetName, centralEnable, ensOptions, ensPreset]);
};

const userdataPropsDiferFromDefault = (
  newUserData: UserDataType
): { hasDifference: boolean; differKey?: string; defaultValue?: any; currentValue?: any; bikeId?: string } => {
  for (const key in newUserData) {
    if (key === 'bikes') {
      continue;
    }

    if ((newUserData[key] ?? '') !== (DefaultUserData[key] ?? ''))
      return {
        hasDifference: true,
        differKey: key,
        defaultValue: DefaultUserData[key],
        currentValue: newUserData[key],
      };
  }

  for (const bikeId in newUserData.bikes) {
    const bike = newUserData.bikes[bikeId];
    for (const key in bike) {
      if ((bike[key] ?? '') !== (DefaultObjectUserData[key] ?? ''))
        return {
          hasDifference: true,
          differKey: key,
          defaultValue: DefaultObjectUserData[key],
          currentValue: bike[key],
          bikeId,
        };
    }
  }

  return { hasDifference: false };
};

export const EntryQuestionSaver = () => {
  const allDyamicFields = useAppSelector((state) => state.ensfields.customquestions);
  const allEntryQuestion = useMemo(
    () => allDyamicFields.filter((o) => entryQuestionPositions.includes(o.position)),
    [allDyamicFields]
  );

  const saverIsActive = useSaverIsActive();

  const userData = useAppSelector((state) => state.userData);
  const devkey = useAppSelector((state) => state.devicemode.devkey);
  const debouncedUserData = useDebounce(userData, 200);

  const presetName = useAppSelector((state) => state.userData.presetName);

  const dispatch = useAppDispatch();

  const presetIdData = useAppSelector((state) => state.options.presetIdData);
  const storageKeyState = { options: { presetIdData } };

  // Load from saved State
  useEffect(() => {
    if (entryQuestionsLoaded || !saverIsActive) {
      if (!saverIsActive) dispatch({ type: 'DISABLE_SAVED_STATE' });

      return;
    }

    const savedState = loadFromLocalStorage(presetName, storageKeyState);
    if (!savedState) return;

    dispatch({ type: 'LOAD_SAVED_STATE', payload: savedState.data });

    entryQuestionsLoaded = true;
  }, [presetName, entryQuestionsLoaded, saverIsActive, presetIdData]);

  // Save on Change
  useEffect(() => {
    if (!saverIsActive) return;

    const newUserData = JSON.parse(JSON.stringify(userData)) as UserDataType;
    if (!newUserData.bikes) return;

    Object.keys(newUserData).forEach((propId: keyof UserDataType) => {
      if (propId === 'bikes') return;
      if (!allEntryQuestion.find((o) => o.name === propId)) {
        delete newUserData[propId];
      }
    });

    Object.keys(newUserData.bikes).forEach((bikeId: string) => {
      const bike = newUserData.bikes[bikeId];
      Object.keys(bike).forEach((propId: keyof BikeUserDataType) => {
        if (!allEntryQuestion.find((o) => o.name === propId)) {
          delete bike[propId];
        }
      });
    });

    const differenceCheck = userdataPropsDiferFromDefault(newUserData);

    if (differenceCheck.hasDifference)
      saveToLocalStorage({ data: newUserData, devicemode: devkey }, presetName, storageKeyState);
    else resetSavedState(presetName, storageKeyState);
  }, [debouncedUserData, entryQuestionsLoaded, devkey, presetName]);

  return null;
};
