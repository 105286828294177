import React, { useEffect, useMemo, useState } from 'react';
import { Container, Divider, Flex, Space, Title, TitleOrder, Input, Box, Group, Chip, Badge } from '@mantine/core';
import { Button } from '@unserkunde/enscompare-components/src/components/components';
import { InputRow } from '@/forms/shared/Common';
import {
  HookedCheckboxRow,
  HookedRadioSwitchRow,
  HookedSelect,
  HookedTextInput,
  HookedDateInput,
  useMantineLabelProps,
  HookedAutocompleteDynamicInput,
  HookedDevicemodeSelect,
} from '@/forms/shared/HookedInputs';
import { useUserData } from '@/hooks';
import FormularEvaluate, { GetEvaluationContext } from '@/lib/FormularEvaluate';
import { DynamicEntryQuestionType } from '@/actions/types/DynamicEntryQuestionType';
import { LinkButton, PureHtml } from '@/forms/shared/style/components';
import { useMemoStateFromFilterDeps } from '@/lib/useMemoStateFromFilterDeps';
import { useIsProcedural } from '@/forms/OverviewControls/proceduralOverviewInput/ProceduralOverviewInput';
import { useCustomSettings, useValueList, useStartValue } from './hooks';
import { CustomProductOptInQuestion } from './components/CustomProductOptInQuestion';
import { GetDevModeType } from '../IsDevMode';
import { EntryQuestionDebugControl } from '../EntryQuestionDebugControl';

type DynamicInputComponentProps = {
  setting: DynamicEntryQuestionType;
  mantine?: boolean;
  disableWrap?: boolean;
  [key: string]: any;
};

const DynamicTextQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedTextInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicAutcompleteQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedAutocompleteDynamicInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicDateQuestion = ({ setting, ...props }: DynamicInputComponentProps) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedDateInput
      field={setting.name}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicSelectSearchQuestion = ({ setting, ...props }) => {
  return (
    <DynamicSelectQuestion
      {...props}
      setting={setting}
      searchable
    />
  );
};

const DynamicSelectQuestion = ({ setting, ...props }) => {
  const values = useValueList(setting.values, false);

  return (
    <HookedSelect
      field={setting.name}
      values={values}
      defaultValue={values.length === 0 ? null : values[0][0]}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicSelectDeviceTypes = ({ setting, ...props }: { setting: DynamicEntryQuestionType; [x: string]: any }) => {
  const { hideOption, ...settingsObj } = useCustomSettings(setting.values);

  const hideValues = useMemo(() => (hideOption ? hideOption.split(',') : []), [hideOption]);

  return (
    <HookedDevicemodeSelect
      field={setting.name}
      hideOptions={hideValues}
      {...settingsObj}
      {...props}
      label={setting.displayName}
      helpText={setting.helpText}
    />
  );
};

const DynamicRadioQuestion = ({ setting, ...props }) => {
  const values = useValueList(setting.values, true);

  return (
    <HookedRadioSwitchRow
      title={setting.displayName}
      helpText={setting.helpText}
      field={setting.name}
      values={values}
      defaultValue={values.length === 0 ? null : values[0][1]}
      {...props}
    />
  );
};

const DynamicCheckboxQuestion = ({ setting, ...props }) => {
  const settingsObj = useCustomSettings(setting.values);

  return (
    <HookedCheckboxRow
      field={setting.name}
      mode={''}
      title={setting.displayName}
      helpText={setting.helpText}
      {...settingsObj}
      {...props}
    />
  );
};

const DynamicConfirmationQuestion = ({ setting, ...props }) => {
  return (
    <HookedCheckboxRow
      field={setting.name}
      helpText={setting.helpText}
      noWrapWithTitle
      {...props}
      title={setting?.displayName}
      label={<PureHtml content={setting.values} />}
    />
  );
};

const DynamicInfotext = ({ setting }) => {
  return (
    <Input.Wrapper>
      <PureHtml content={setting.values} />
    </Input.Wrapper>
  );

  return (
    <InputRow
      title={setting.displayName}
      helpText={setting.helpText}>
      <PureHtml content={setting.values} />
    </InputRow>
  );
};

const DynamicInfotextToggle = ({ setting }) => {
  const [isOpen, setIsOpen] = useState(false);

  const displayTexts = setting.displayName.split('|');

  return (
    <InputRow
      title=''
      helpText={setting.helpText}>
      <div>
        <LinkButton onClick={() => setIsOpen(!isOpen)}>
          {displayTexts.length === 1 ? displayTexts[0] : displayTexts[isOpen ? 1 : 0]}
        </LinkButton>

        {!isOpen ? null : <PureHtml content={setting.values} />}
      </div>
    </InputRow>
  );
};

const DynamicHeader = ({ setting }) => {
  const settingsObj = useCustomSettings(setting.values);
  const { label } = useMantineLabelProps(setting.displayName, setting.helpText);

  return (
    <div>
      <Title
        mt={'md'}
        order={settingsObj?.order ? (parseInt(settingsObj.order) as TitleOrder) : 2}>
        {label}
      </Title>
      <Divider mt={0} />
    </div>
  );
};

const DynamicSelectButtons = ({ setting, bikeId }) => {
  const values = useMemo(() => {
    const result = setting.values.split(/\r?\n/);
    return result.map((s) => s.split(':'));
  }, [setting.values]);

  const [_, onChange] = useUserData(setting.name, bikeId);

  return (
    <Flex
      gap='xs'
      wrap='wrap'>
      {values.map((v) => (
        <Button
          key={v[0]}
          size={'xs'}
          onClick={() => onChange(v[0])}>
          {v[1]}
        </Button>
      ))}
    </Flex>
  );
};

export const evaluateFilter = (
  dynamicFieldSetting: DynamicEntryQuestionType,
  bikeId: string | null,
  state: RootState
) => {
  if (!dynamicFieldSetting.displayFilter) return true;

  const context = GetEvaluationContext(state, bikeId);

  const res = FormularEvaluate(dynamicFieldSetting.displayFilter, context);

  return res;
};

export const useDisplayFilter = (setting: DynamicEntryQuestionType, bikeId: string) => {
  const state = useMemoStateFromFilterDeps(setting.displayFilter);
  return useMemo(() => {
    return evaluateFilter(setting, bikeId, state);
  }, [state, setting, bikeId]);
};

const DynamicEntryQuestion = <T extends React.PropsWithChildren>({
  setting,
  wrapper = null,
  wrapperProps = undefined,
  visibilityChange,
  ...props
}: {
  setting: DynamicEntryQuestionType;
  wrapper?: React.ComponentType<T> | null;
  wrapperProps?: Omit<T, 'children'>;
  visibilityChange?: (setting: DynamicEntryQuestionType, visible: boolean) => void;
  [x: string]: any;
} & T) => {
  const { fieldType } = setting;

  const Control = useMemo(() => {
    if (fieldType === 'text') return DynamicTextQuestion;
    if (fieldType === 'date') return DynamicDateQuestion;
    if (fieldType === 'select') return DynamicSelectQuestion;
    if (fieldType === 'selectSearch') return DynamicSelectSearchQuestion;
    if (fieldType === 'radio') return DynamicRadioQuestion;
    if (fieldType === 'infoText') return DynamicInfotext;
    if (fieldType === 'toggleInfoText') return DynamicInfotextToggle;
    if (fieldType === 'checkbox') return DynamicCheckboxQuestion;
    if (fieldType === 'confirmation') return DynamicConfirmationQuestion;
    if (fieldType === 'selectButtons') return DynamicSelectButtons;
    if (fieldType === 'header') return DynamicHeader;
    if (fieldType === 'deviceTypes') return DynamicSelectDeviceTypes;
    if (fieldType === 'autocomplete') return DynamicAutcompleteQuestion;
    if (fieldType === 'customProductOptIn') return CustomProductOptInQuestion;
    // eslint-disable-next-line react/display-name
    return () => <span>Entry option Type not found</span>;
  }, [fieldType]);

  const isProceduralCheckout = useIsProcedural();

  const usedSetting = useMemo(() => {
    const displayName = isProceduralCheckout
      ? setting.displayNameProcedural || setting.displayName
      : setting.displayName;
    return { ...setting, displayName };
  }, [setting, isProceduralCheckout]);

  const show = useDisplayFilter(usedSetting, props.bikeId);
  useStartValue(show, usedSetting, props.bikeId);

  const customOptions = useCustomSettings(setting.options);
  useEffect(() => {
    if (!visibilityChange) return;
    visibilityChange(setting, show);
  }, [show, setting, visibilityChange]);

  // @ts-ignore
  const inputControl = useMemo(
    () => (
      <Control
        {...props}
        {...customOptions}
        bikeId={props.bikeId}
        setting={usedSetting}
      />
    ),
    [usedSetting, ...Object.values(props)]
  );

  if (!show) return null;

  let usedWrapper: React.ComponentType<React.PropsWithChildren> = wrapper;

  if (GetDevModeType() == 'fields') {
    const TmpWrap: React.ComponentType<React.PropsWithChildren> = wrapper;
    usedWrapper = (props: React.PropsWithChildren) => {
      const { children, ...wrapperProps } = props;
      return (
        <Group position='apart'>
          {TmpWrap && <TmpWrap {...wrapperProps}>{children}</TmpWrap>}
          {!TmpWrap && children}
          <EntryQuestionDebugControl setting={usedSetting} />
        </Group>
      );
    };
  }

  if (!usedWrapper) return inputControl;

  const Wrapper = usedWrapper;
  // @ts-ignore
  return <Wrapper {...wrapperProps}>{inputControl}</Wrapper>;
};

export default DynamicEntryQuestion;

export const DynamicEntryQuestionDisplay = ({ setting, bikeId = null }) => {
  const { fieldType, name } = setting;

  const [value] = useUserData(name, bikeId);
  const values = useValueList(setting.values);

  let result = '';

  switch (fieldType) {
    case 'select':
    case 'selectSearch':
    case 'radio':
      const hit = values.find((v) => v[0] === value);
      result = !hit ? value : hit[1];
      break;
    case 'checkbox':
    case 'confirmation':
      result = value ? 'Ja' : 'Nein';
      break;
    default:
      result = value;
      break;
  }

  return <>{result || ''}</>;
};

export const EntryQuestionContainer = ({
  children,
  ...props
}: React.ComponentProps<typeof Container> & { [s: string]: any }) => {
  return (
    <Container
      px={0}
      {...props}>
      <Flex
        gap='md'
        direction='column'>
        {children}
      </Flex>
      <Space h='lg' />
    </Container>
  );
};
